<template>
    <div class="page">
        <el-row :gutter="10" class="h100">
            <el-col :span="4" class="h100 columnBox" style="overflow:auto">
                <div :class="['item pointer', item.id == classifyId ? 'selItem': '']" v-for="item in classifyList"
                     :key="item.id"
                     @click="selClassify(item)">
                    {{item.topicName}}
                </div>
            </el-col>
            <el-col :span="20" class="h100">
                <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item prop="title">
                                <el-input size="small" v-model.trim="searchForm.title" maxlength="20" placeholder="请输入藏品名称或编号"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询
                                </el-button>
                                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置
                                </el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="text_right p_r1">
                            <el-button type="primary" size="small" plain :disabled="dataListSelections.length <= 0"
                                       @click="isHideChange()">隐藏
                            </el-button>
                            <el-button type="primary" size="small" plain v-show="hasPermission('collectionDatabase:releaseAdmin:lowerShelf')"
                                       :disabled="dataListSelections.length <= 0"
                                       @click="soldOut()">下架
                            </el-button>
                            <el-button type="primary" size="small" v-show="hasPermission('collectionDatabase:releaseAdmin:publishFieldSet')"
                                       plain @click="fieldSet">发布字段设置</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="bg-white top">
                    <el-row>
                        <el-col :span="18">
                            <AdvancedQuery ref="advancedQuery" :moduleId="moduleId"
                                           @getDataList="getDataList"></AdvancedQuery>
                        </el-col>
                        <el-col :span="6" class="text_right">
                            <el-button-group class="m_r1">
                                <el-button size="small" plain icon="el-icon-refresh-left"
                                           @click="refreshAll"></el-button>
                                <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                            </el-button-group>
                        </el-col>
                    </el-row>
                    <el-table
                            :data="dataList"
                            v-loading="loading"
                            size="small"
                            height="calc(100% - 80px)"
                            @selection-change="selectionChangeHandle"
                            class="table" ref="multipleTable">
                        <el-table-column
                                type="selection"
                                v-if=""
                                width="50" fixed>
                        </el-table-column>
                        <el-table-column prop="resource_sort" width="110" label="排序号">
                            <template slot-scope="scope">
                                {{scope.row.resource_sort}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="thumbnail" width="110" label="缩略图">
                            <template slot-scope="scope">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.thumb_path" fit="contain">
                                    <el-image slot="error" fit="contain" style="width: 100px; height: 100px"
                                              :src="require('@/assets/img/thumbnail.png')"></el-image>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="on_show" width="120" sortable label="所属栏目">
                            <template slot-scope="scope">
                                {{scope.row.topic_name}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="on_show" width="120" sortable label="是否隐藏">
                            <template slot-scope="scope">
                                <el-switch :disabled="scope.row.on_review === 1" v-model="scope.row.on_show"
                                           :active-value="1" :inactive-value="0"
                                           @change="isHideChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="on_review" width="120" sortable label="是否审核">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.on_review" :active-value="0" :inactive-value="1"
                                           @change="isAuditChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="click_total" width="120" sortable label="点击数">
                            <template slot-scope="scope">
                                {{scope.row.click_total}}
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(item,index) in columnList" :key="index"
                                         v-if="item.fieldEname != 'thumb_path'"
                                         :prop="item.fieldEname" width="160"
                                         show-overflow-tooltip sortable
                                         :label="item.fieldName">
                            <template slot-scope="scope">
                                <span v-if="item.fieldEname!='thumb_path'">{{scope.row[item.fieldEname]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="150" label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" plain v-show="hasPermission('collectionDatabase:details:view')"
                                           @click="view('view', scope.row)">紧急调整
                                </el-button>
                                <el-button size="mini" type="primary" plain
                                           v-show="hasPermission('collectionDatabase:releaseAdmin:lowerShelf')"
                                           @click="soldOut(scope.row.id)">下架
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageSize"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!--查看-->
        <PublishedForm ref="publishedForm" @refreshDataList="refreshList"></PublishedForm>
        <!--发布-->
        <el-dialog title="发布字段设置" width="50%" :visible.sync="visible">
            <el-transfer :titles="['发布字段可见列表', '发布字段隐藏列表']"
                         filterable
                         :filter-method="filterMethod"
                         filter-placeholder="请输入关键字"
                         :props="{key: 'id', label: 'fieldName'}"
                         v-model="fieldVal"
                         :data="fieldData">
            </el-transfer>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="fieldSetShow" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import PublishedForm from './publishedForm' // 查看弹窗
    export default {
        components: {DraggablePop, AdvancedQuery, PublishedForm},
        data() {
            return {
                classifyList: [],
                classifyId: null, //默认全部藏品
                searchForm: {
                    title: "",
                },
                dataList: [],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
                columnList: [],
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
                moduleId: '946049935122366464',   // 当前请求拖拽排序数据id
                moduleIdFieldSet: '965943998453645312', // 发布字段设置
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                // 发布字段设置
                visible: false,
                fieldData: [],
                fieldVal: [],
                filterMethod(query, item) {
                    return item.fieldName.indexOf(query) > -1;
                }
            }
        },
        mounted() {
            this.collList()
            this.refreshList();
        },
        methods: {
            // 选择分类
            selClassify(item) {
                this.classifyId = item.id
                this.pageNo = 0
                this.refreshList();
            },
            getTableByResourceName() {
                this.$axios(this.api.original.metaDataDirectoryList, {
                    resourceName: '藏品资源',
                }, 'get').then(res => {
                    if (res.status) {
                        this.moduleId = res.data.records[0].id;
                        this.getColumnList();
                    }
                })
            },
            // 刷新按钮回到全部列表
            refreshAll(){
                this.onScene = false
                this.dataListQuery.queryConditions = []
                this.searchForm.title = ''
                this.pageNo = 0
                this.refreshList()
            },
            // 获取数据列表
            refreshList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    return
                }
                this.dataListQuery.sceneId = ''
                this.loading = true
                this.$axios(this.api.rcPage.pushAdminList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'type': this.searchForm.type,
                    'searchNumOrName': this.searchForm.title,
                    'originalId': this.moduleId,
                    'topicId': this.classifyId
                }, 'get').then(data => {
                    this.dataList = data.data.records
                    this.total = parseInt(data.data.total);
                    this.getTableByResourceName()
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout();
                    })
                })
            },
            // 获取数据列表
            collList() {
                this.$axios(this.api.rcPage.pubTopicList, {}, 'get').then(res => {
                    let a = {
                        'id': null,
                        'topicName': '全部藏品'
                    }
                    this.classifyList = res.data.records
                    this.classifyList.unshift(a)
                })
            },
            // 获取显示字段
            getColumnList() {
                var that = this;
                this.columnList = []
                that.$axios(this.api.sys.getByOriginalId + this.moduleId, '', "get").then((res) => {
                    if (res.status) {
                        res.data.map(item => {
                            if (item.logicType == 0) {
                                this.columnList.push(item)
                            }
                        })
                        this.$nextTick(() => {
                            this.loading = false
                            this.$refs.multipleTable.doLayout();
                        })
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 查看
            view(method, row) {
                this.$refs.publishedForm.init(row);
            },
            // 下架
            soldOut(id) {
                let collectionIds = []
                if (id != null) {
                    collectionIds.push(id)
                } else {
                    collectionIds = this.dataListSelections.map(item => {
                        return item.id
                    })
                }

                this.$confirm(`确定下架吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.rcPage.pushOperateCollection, {
                        'collectionIds': collectionIds,
                        'showOrReviewOrPublish': 3,
                        'state': 1
                    }, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 是否隐藏
            isHideChange(row) {

                let collectionIds = []
                let onShow = 1;
                if (row != null && row.id != null) {
                    collectionIds.push(row.id)
                    onShow = row.on_show
                } else {
                    collectionIds = this.dataListSelections.map(item => {
                        console.log(item.on_review, "-")
                        if (item.on_review === 0) {
                            return item.id
                        }
                    })
                    onShow = 1
                }

                this.$axios(this.api.rcPage.pushOperateCollection, {
                    'collectionIds': collectionIds,
                    'showOrReviewOrPublish': 1,
                    'state': onShow
                }, "post").then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.refreshList()
                    }
                })
            },
            // 是否审核（单个）
            isAuditChange(row) {
                //this.loading = true
                this.$axios(this.api.rcPage.pushOperateCollection, {
                    'collectionIds': [row.id],
                    'showOrReviewOrPublish': 2,
                    'state': row.on_review
                }, "post").then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.loading = false
                        this.refreshList()
                    }
                })
            },
            // 发布字段设置
            fieldSet() {
                this.visible = true;
                this.$axios(this.api.rcPage.pushFieldSet + this.moduleIdFieldSet, {}, "get").then((res) => {
                    if (res.status) {
                        this.fieldData = res.data[0]
                        this.fieldVal = res.data[1].map(item => {
                            return item.id;
                        })
                    }
                })
            },
            // 发布字段显示与隐藏
            fieldSetShow() {
                this.loading = true;
                let originalIds = this.fieldData.map(item => {
                    return item.id
                })

                originalIds = originalIds.filter(item => this.fieldVal.findIndex(itema => itema === item) === -1)

                let flag = true;
                let flag1 = true;
                // 左侧显示栏目
                this.$axios(this.api.rcPage.pushFildShow, {
                    originalIds: originalIds,
                    onShow: 0
                }, "post").then((res) => {
                    flag = res.status
                })
                // 右侧隐藏栏目
                this.$axios(this.api.rcPage.pushFildShow, {
                    originalIds: this.fieldVal,
                    onShow: 1
                }, "post").then((res) => {
                    flag1 = res.status
                })
                if (flag && flag1) {
                    this.$message.success("设置成功")
                }
                this.loading = false
                this.visible = false
            },
            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.pageSize
                data.current = this.pageNo
                data.pageId = this.moduleId
                data.collectionPageType = 2
                data.topicId = this.classifyId
                data.searchNumOrName = this.searchForm.title


                this.$axios(this.api.rcPage.listReleaseQueryCollection, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total);
                    }
                })
            },
            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.columnList = data;
                this.setShow = state;
                this.refreshList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 0
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            resetSearch() {
                this.dataListQuery=[]
                this.$refs.advancedQuery.addForm.sceneId=''
                this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.onScene = false
                    this.pageNo = 0
                    this.refreshList()
                })
            },
            search() {
                this.pageNo = 0
                this.refreshList()
            }
        }
    }
</script>
<style>
    .el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
        font-size: 13px !important;
    }

    .columnBox {
        padding: 10px 0;
        background: #ffffff;
    }

    .columnBox .item {
        text-align: center;
        padding: 10px;
        color: #333333;
        font-size: 14px;
    }

    .columnBox .selItem, .columnBox .item:hover {
        background: #eeeeee;
    }
</style>
